
[role='banner'],
.sitename,
.content__wrap > h1 {
  margin-bottom: 0.1545em;
  margin-right: 4px;
  margin-right: 0.25rem;

  body.page--home & {
    display: inline-block;
    margin-bottom: 0;
  }

  a {
    @include font-size(2.2);
    @include imagereplacement( 'danielhandler', 'svg', 36, 244);

    border-bottom: none;
    color: #000000;
    font-weight: normal;
    line-height: 1;
    text-transform: uppercase;

    .no-svg & {
      background-image: none;
      overflow: visible;
      text-indent: 0;
      white-space: normal;
    }
  }
}
