// ----------------------------------------------------
// Icons

.icon {
  cursor: pointer;
  [id="content"] .icon {
    margin-top: 0.618em;
  }
}

.lt-ie9 .icon, .no-svg .icon {
  font-family: "Finlek-Regular", $f-serif;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 1;
  padding-top: 0;

  @include alias;
}

// Menu icons
// Fallback for IE8 is introduced above and in ie.css
.icon--books {
  @include svgreplacement("menu-books", "svg", $imagewidth: 63, $imageheight: 42);
}
.icon--contact {
  @include svgreplacement("menu-contact", "svg", $imagewidth: 84, $imageheight: 44);
}

// Icons below content
.page--contact .icon--expand {
  // display: none !important;
}
.icon--expand, .icon--expand.active:hover {
  .js & {
    @include imagereplacement("icon-telescope-shut", "svg", $imagewidth: 72, $imageheight: 20);
  }
  .js.lt-ie9, .no-svg & {
    @include imagereplacement("icon-telescope-shut", "png", $imagewidth: 72, $imageheight: 20);
  }
}
.icon--expand:hover, .icon--expand:focus, .icon--expand.active {
  .js & {
    @include svgreplacement("icon-telescope-open", "svg", $imagewidth: 72, $imageheight: 20);
  }
  .js.lt-ie9, .no-svg & {
    @include svgreplacement("icon-telescope-open", "png", $imagewidth: 72, $imageheight: 20);
  }
}
.icon--mystery {
  .js & {
    @include svgreplacement("icon-envelope", "svg", $imagewidth: 50, $imageheight: 50);
  }
  .no-svg & {
    @include svgreplacement("icon-envelope", "png", $imagewidth: 50, $imageheight: 50);
  }
}


.icon--mystery.poked {
  color: red; // TK
  transform: rotate(-2deg);
  // animation: shake .5s linear;
}
.icon--mystery:hover {
  animation: shake 0.5s linear;
}

@keyframes shake {
  8%, 41% {
    transform: translateX(-10px);
  }
  25%, 58% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%, 100% {
    transform: translateX(0);
  }
}