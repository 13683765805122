/*
Font:     Baskerville
Style:    Regular
URL:    https://www.youworkforthem.com/font/T1175/baskerville
Foundry:  URW++
Foundry:  https://www.youworkforthem.com/designer/204/urw
Copyright:  Copyright 2010 URW++ Design & Development
Version:  17
Created:  March 20, 2015
License:  https://www.youworkforthem.com/font-license
License:  The WebFont(s) listed in this document must follow the YouWorkForThem
      WebFont license rules. All other parties are strictly restricted
      from using the WebFonts(s) listed without a purchased license.
      All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'Baskerville';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/baskerville.eot');
  src: url('/assets/fonts/baskerville.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/baskerville.woff2') format('woff2'),
  url('/assets/fonts/baskerville.woff') format('woff'),
  url('/assets/fonts/baskerville.ttf') format('truetype'),
  url('/assets/fonts/baskerville.svg#youworkforthem') format('svg');
}

/*
Font:     Baskerville
Style:    Regular Italic
URL:    https://www.youworkforthem.com/font/T1175/baskerville
Foundry:  URW++
Foundry:  https://www.youworkforthem.com/designer/204/urw
Copyright:  Copyright 2010 URW++ Design & Development
Version:  17
Created:  March 20, 2015
License:  https://www.youworkforthem.com/font-license
License:  The WebFont(s) listed in this document must follow the YouWorkForThem
      WebFont license rules. All other parties are strictly restricted
      from using the WebFonts(s) listed without a purchased license.
      All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'Baskerville';
  font-style: italic;
  font-weight: normal;
  src: url('/assets/fonts/baskerville-regular-italic.eot');
  src: url('/assets/fonts/baskerville-regular-italic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/baskerville-regular-italic.woff2') format('woff2'),
  url('/assets/fonts/baskerville-regular-italic.woff') format('woff'),
  url('/assets/fonts/baskerville-regular-italic.ttf') format('truetype'),
  url('/assets/fonts/baskerville-regular-italic.svg#youworkforthem') format('svg');
}

/*
Font:     Baskerville
Style:    Medium
URL:    https://www.youworkforthem.com/font/T1175/baskerville
Foundry:  URW++
Foundry:  https://www.youworkforthem.com/designer/204/urw
Copyright:  Copyright 2010 URW++ Design & Development
Version:  17
Created:  March 20, 2015
License:  https://www.youworkforthem.com/font-license
License:  The WebFont(s) listed in this document must follow the YouWorkForThem
      WebFont license rules. All other parties are strictly restricted
      from using the WebFonts(s) listed without a purchased license.
      All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'Baskerville';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/baskerville-medium.eot');
  src: url('/assets/fonts/baskerville-medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/baskerville-medium.woff2') format('woff2'),
  url('/assets/fonts/baskerville-medium.woff') format('woff'),
  url('/assets/fonts/baskerville-medium.ttf') format('truetype'),
  url('/assets/fonts/baskerville-medium.svg#youworkforthem') format('svg');
}

/*
Font:     Baskerville
Style:    Medium Italic
URL:    https://www.youworkforthem.com/font/T1175/baskerville
Foundry:  URW++
Foundry:  https://www.youworkforthem.com/designer/204/urw
Copyright:  Copyright 2010 URW++ Design & Development
Version:  17
Created:  March 20, 2015
License:  https://www.youworkforthem.com/font-license
License:  The WebFont(s) listed in this document must follow the YouWorkForThem
      WebFont license rules. All other parties are strictly restricted
      from using the WebFonts(s) listed without a purchased license.
      All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'Baskerville';
  font-style: italic;
  font-weight: bold;
  src: url('/assets/fonts/baskerville-medium-italic.eot');
  src: url('/assets/fonts/baskerville-medium-italic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/baskerville-medium-italic.woff2') format('woff2'),
  url('/assets/fonts/baskerville-medium-italic.woff') format('woff'),
  url('/assets/fonts/baskerville-medium-italic.ttf') format('truetype'),
  url('/assets/fonts/baskerville-medium-italic.svg#youworkforthem') format('svg');
}

/*
Font:     Baskerville
Style:    Bold
URL:    https://www.youworkforthem.com/font/T1175/baskerville
Foundry:  URW++
Foundry:  https://www.youworkforthem.com/designer/204/urw
Copyright:  Copyright 2010 URW++ Design & Development
Version:  17
Created:  March 20, 2015
License:  https://www.youworkforthem.com/font-license
License:  The WebFont(s) listed in this document must follow the YouWorkForThem
      WebFont license rules. All other parties are strictly restricted
      from using the WebFonts(s) listed without a purchased license.
      All details above must always remain unaltered and visible in your CSS.
*/

// @font-face {
//   font-family: 'Baskerville';
//   src: url('/assets/fonts/baskerville-bold.eot');
//   src: url('/assets/fonts/baskerville-bold.eot?#iefix') format('embedded-opentype'),
//              url('/assets/fonts/baskerville-bold.woff2') format('woff2'),
//        url('/assets/fonts/baskerville-bold.woff') format('woff'),
//        url('/assets/fonts/baskerville-bold.ttf') format('truetype'),
//        url('/assets/fonts/baskerville-bold.svg#youworkforthem') format('svg');
//   font-weight: bold;
//   font-style: normal;
// }
