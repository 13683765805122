// ----------------------------------------------------
// Backgrounds

body {
  background: none;
  background-image: none;
  background-repeat: no-repeat;
  @media screen and (min-width: ($breakpoint-background + 1)) {
    background-attachment: fixed;
    background-position: bottom right;
    // Carrot
    &.background2 {
      background-image: url('/assets/images/bg/dh-bg-2-1x.jpg');
      .backgroundsize & {
        background-image: url('/assets/images/bg/dh-bg-2-2x.jpg');
        background-size: 570px 345px ; // 1140 690
      }
    }
    // Shavings
    &,
    &.background1 {
      background-image: url('/assets/images/bg/dh-bg-1-1x.jpg');
      .backgroundsize & {
        background-image: url('/assets/images/bg/dh-bg-1-2x.jpg');
        background-size: 320px 395px; // 640 790
      }
    }
    &.background0 {
      background-image: none;
      .backgroundsize & {
        background-image: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-background) {
  .mobile-footer-image {
    background-size: cover;
    display: block;
    margin-left: auto;
    max-width: 100%;
    width: 100%;
    // Carrot
    .background2 & {
      background-image: url('/assets/images/bg/dh-bg-2-2x.jpg'); // Carrot
      height: 163px;
      width: 300px;
    }
    // Shavings
    &,
    .background1 & {
      background-image: url('/assets/images/bg/dh-bg-1-2x.jpg'); // Shavings
      height: 239px;
      width: 221px;
    }
    &.background0 {
      background-image: none;
    }
  }
}
