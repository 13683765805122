
dt,
dd.visible {
  display: inline;
  opacity: 1;

  @media screen and (min-width: $breakpoint-mobile) {
    @include slight-background;
  }
}

dd,
dd.invisible {
  display: none;
  opacity: 0;
}

.before {
  @include telescope-link;

  display: inline;
}
.before:hover,
.before:focus {
  @include telescope-link--active;
}

.no-js {
  dt {
    display: none;
  }

  dl,
  dd {
    display: inline-block;
  }

  .before {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden;
  }

  .after {
    display: inline !important;
    opacity: 1 !important;
    visibility: visible;
  }
}
