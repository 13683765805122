
a.accordion-toggle,
a[href^='#']:not([href='#']) {
  @include telescope-link;

  &:hover,
  &:focus,
  &:active {
    @include telescope-link--active;
  }

  .open-sesame &,
  .no-js & {
    background: transparent;
    color: $c-black;
    cursor: text;
    pointer-events: none;
  }
}

a.accordion-toggle.active {
  @include telescope-link--active;
}

/* Accordion menu */
.accordion {
  @include alias(normal);
  @include font-size(1.618);
  @include easeOutCirc;

  height: auto;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: visible; /* Prevents iPhone bug */

  @media screen and (max-width: $breakpoint-mobile) {
    @include font-size(1.2135);
  }

  .no-js &,
  &.visible,
  .open-sesame & {
    @include easeOutCirc;

    display: block;
    height: auto;
    margin: 0.618rem 0;
    max-height: none;
    opacity: 1;
    visibility: visible;
  }

  .page--books & {
    @include f-base-italic;
  }

  strong {
    @include f-base;

    font-style: normal;
  }

  ul ul {
    display: block;
    margin-top: 0.618em;
    padding-bottom: 0.309em;
  }

  h2 {
    display: none;
    opacity: 0;
  }
}

.accordion {
  .visible em {
    display: block;
    margin: 0 0 0.618rem 0;
  }
}

.accordion--lemonysnicket {
  dl ~ dl {
    margin-top: 100px;
  }
}
