.page--perdiempress {
  h1.sitename {
    display: none;
  }

  [id='content'] {
    h3 {
      @include font-size(1.1);

      letter-spacing: 0.025em;
      padding-top: 1rem;
    }
    ul:first-of-type li {
      @include font-size(1.618);
    }
  }
}
