// ---------------------------------------------------------------
// Main Content styles

strong {
  @include f-base-italic;

  font-weight: normal;
}

[id='content'] {
  @include font-size(1.25);

  display: none;
  line-height: 1.3;
  overflow: visible;
  padding-right: 1rem;
  padding-top: 4em;

  @media screen and (min-width: $breakpoint-mobile) {
    @include font-size(1.5);

    line-height: 1.5;
    padding-right: 0;
  }

  html.wf-active & {
    display: block;
    /* Web fonts have loaded */
  }

  .no-js & {
    display: block;
    opacity: 1;
  }

  h2,
  p {
    @include font-size(1.5);

    color: $c-black;
    font-style: normal;
    font-weight: normal;
    line-height: 1.45;
    margin: 0;
    padding: 0.618rem 0 0 0;

    & + ul {
      padding-bottom: 0.155rem;
      padding-top: 1.618rem;
    }

    @media screen and (min-width: $breakpoint-mobile) {
      @include font-size(2);

      margin: 0;
      padding: 0;

      & + ul {
        padding-top: 0.618rem;
      }
    }
  }

  h2 {
    line-height: 1.125;
    margin-bottom: 0.325em;
    margin-top: 0.125em;
  }

  h3 {
    @include font-size(0.75);

    font-weight: normal;
    line-height: 1.2;
    padding-bottom: 0.618rem;
    padding-top: 1.618rem;

    @media screen and (min-width: $breakpoint-mobile) {
      @include font-size(1);
    }
  }

  em,
  .booktitle {
    @include f-base-italic;
  }

  h1 + p {
    body.page--home & {
      display: inline;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility; // Fix the character spacing for headings

      @media screen and (min-width: $breakpoint-mobile) {
        @include slight-background;
      }
    }
  }

  li {
    @include font-size(1);

    color: #060606;
    display: block;
    line-height: 1.2;
    list-style: none;
    padding-bottom: 1em;

    @media screen and (min-width: $breakpoint-mobile) {
      @include font-size(1.5);

      line-height: 1.4;
      padding-bottom: 0.309em;
    }
  }

  dl {
    display: inline;
  }

  h2 + div.visible {
    padding: 1px 0 1rem 0;

    @media screen and (max-width: $breakpoint-mobile) {
      margin-left: 1px;
    }
  }

  /* Outgoing links */
  a[href^='http']:not([href*='danielhandler.']),
  a[href^='//'],
  a[href^='mailto'],
  a[href*='//']:not([href*='danielhandler.']) {
    &,
    &:visited {
      background: rbga(255,255,255,0.25);
      border-bottom: 1px dashed $c-medium-gray;
      color: $c-black;
      padding-bottom: 0;
    }
    &:hover {
      border-bottom-color: #aaaaaa;
      color: #666666;
    }
  }

  a[href^='mailto']:after {
    content: ' ➢';
  }

  footer {
    margin-top: 1rem;
  }
}

.accordion.accordion--tour.visible {
  @include f-base;

  font-style: normal;
}
