/*! ---------------------------------------------------
// DANIELHANDLER.COM
// by Max FENTON
// for Daniel HANDLER.
// 2014–2015, 2018–
// http://danielhandler.com/_/styles/styles.css
// v=1.1.0
// ------------------------------------------------- */

// ----------------------------------------------------

@import 'webfonts';


// VARIABLES ------------------------------------------
// ----------------------------------------------------

@import 'variables';

// ----------------------------------------------------
// BOILERPLATE / INITIALIZR ---------------------------
// ----------------------------------------------------

@import 'boilerplate-base';
// @import 'ie';

/* ----------------------------------------------------
// DANIEL HANDLER -------------------------------------
// ---------------------------------------------------- */

// ----------------------------------------------------
// Mixins

@import 'mixins';

// ----------------------------------------------------
// Base

html {
  box-sizing: border-box;
  // -moz-osx-font-smoothing: grayscale;
  // @include alias;
  overflow-y: scroll;
  // @include f-base;
  // line-height: $base-line-height / $base-font-size;
  // background-color: $base-background-color;
  // color: $base-text-color;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}

body {
  @include f-base;

  font-size: 1em;
  line-height: 1;
  padding-left: 20px;
  padding-left: 1.25rem;
}

a {
  border-bottom: 1px solid #cccccc;
  color: #000000;
  text-decoration: none;
  transition: background $transition-time ease;
  transition: background-color $transition-time ease;
  transition: color $transition-time ease;
}
a:hover,
a:active,
a:focus {
  border-bottom-color: #aaaaaa;
  color: #333333;
}

a,
a:hover,
a:active,
a:visited {
  outline: 0;
}

img {
  border: 0;
  outline: 0;
}

// ----------------------------------------------------
// Layout

body {
  @media screen and (min-width: 1400px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    position: relative;
  }
}

.mainmenu {
  position: absolute;
  right: 16px;
  right: 1rem;
  top: 10px;
  top: 0.625rem∂;

  @media screen and (max-width: $breakpoint-mobile) {
    right: 40px;
    right: 2.5rem;
    top: 20px;
    top: 1.25rem;
  }
}

.page-footer {
  height: auto;
}

@media screen and (min-width: $breakpoint-mobile) {
}

@media screen and (min-width: ($breakpoint-background + 1)) {
  .body-wrap {
    margin-bottom: #{ -1 * $footer-height };
    min-height: 100%;
    // padding-bottom: ( 2/3 * #{$footer-height});
    width: 90%;
  }
  .body-wrap:after {
    content: '';
    display: block;
    height: $footer-height;
  }
  .page-footer {
    clear: both;
    height: ($footer-height - 10px);
    position: relative;
  }
  .footer__wrap {
    bottom: 16px;
    position: absolute;
  }
}

[id='content'] article {
  max-width: 33em;
}

.wrapper {
  margin: 0;
  width: 100%;

  @media screen and (min-width: $breakpoint-mobile) {
    margin: 0 5%;
    width: 90%;
  }
}

// ----------------------------------------------------
// Typography

body {
  @include alias;
  @include f-base;

  background-color: $base-background-color;
  color: $base-font-color;
  font-size: $base-font-size;
  line-height: $base-line-height;

  @media screen and (max-width: $breakpoint-mobile) {
    @include font-size(0.625);
  }
}

h1,
h2,
h3 {
  @include alias;

  line-height: $header-line-height;
  margin: 0;
  text-rendering: optimizeLegibility; // Fix the character spacing for headings
}

p {
  margin: 0 0 ($base-spacing / 2);
}

a {
  transition: color 0.1s linear;
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

// ----------------------------------------------------
// Footer

@import 'partials/footer';

// ----------------------------------------------------
// Telescoping

@mixin telescope-link {
  background-color: $c-telescope-bg;
  background-color: $c-telescope-bg-a;
  border-bottom: none;
  color: $c-black;
  cursor: pointer;
  margin: -1px;
  padding: 1px 2px;
}

@mixin telescope-link--active {
  background-color: $c-telescope-bg-active;
  background-color: $c-telescope-bg-active-a;
  border-bottom: none;
  color: #111111;
  margin: -1px;
  padding: 1px 2px;
}

@import 'partials/telescope';

@import 'partials/header';

@import 'partials/content';

@import 'partials/perdiempress';

@import 'partials/accordion';

@import 'partials/mainmenu';

// ----------------------------------------------------
// Icons
@import 'icons';

// ----------------------------------------------------
// Background
@import 'backgrounds';

// ----------------------------------------------------
// Utilities
@import 'utilities';

/* -------------------------------------------------

         ( ª ± * )

------------------------------------------------- */
