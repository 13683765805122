.mainmenu {
  ul {
    @include font-size(1);

    height: auto;
    line-height: 1.2;
    list-style: none;
    text-transform: lowercase;

    @media screen and (max-width: $breakpoint-mobile) {
      @include font-size(0.75);
    }
  }

  li {
    display: inline-block;
    padding: 0;
    text-align: right;
    vertical-align: top;

    &:first-child {
      .svg & {
        padding-top: 3px; /* Kludge */
      }
    }

    .no-svg & {
      height: auto;
    }
  }

  a {
    border-bottom: none;
    padding: 5px;
  }

  .icon {
    background-position: center center;
    opacity: 0.5;

    &:hover {
      opacity: 0.9;
    }
    // &:focus,
    &.active {
      opacity: 1;
      outline: 0;
    }
  }
}
