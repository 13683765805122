// ----------------------------------------------------
// MEASUREMENTS
$footer-base-height: 120px;
$footer-image-height: 390px;
$footer-height: $footer-base-height;
$footer-height-mobile: 510px;
// $footer-base-height: $footer-base-height * 1rem / 16px;
// $footer-image-height: $footer-image-height * 1rem / 16px;
// $footer-height: $footer-height * 1rem / 16px;

// ----------------------------------------------------
// TRANSITIONS

@mixin easeOutCirc {
	-webkit-transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
	        transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

@mixin easeInOutCirc {
	-webkit-transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
	        transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

$transition-time: 0.5s;

// ----------------------------------------------------
// BREAKPOINTS
$breakpoint-background: 568px;
$breakpoint-mobile: 31em;
$breakpoint-mobile: $breakpoint-background;

// ----------------------------------------------------
// TYPOGRAPHY

// Font Faces
$f-serif: Georgia, Times, serif;
$base-fonts: $f-serif;
$header-fonts: $base-fonts;

@mixin f-base {
	font-family: "Baskerville", "Baskerville Regular", $f-serif;
}
@mixin f-base-italic {
	font-family: "Baskerville", "Baskerville Italic", $f-serif;
	font-style: italic;
}

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$header-line-height: 1.25;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$base-z-index: 0;

// ----------------------------------------------------
// COLORS
$c-blue: #477dca;
$c-dark-gray: #333333;
$c-medium-gray: #808080;
$c-light-gray: #dddddd;
$c-light-red: #fbe3e4;
$c-light-yellow: #fff6bf;
$c-light-green: #e6efc2;
$c-black: #222222;
$c-telescope-bg: #e9e7e5;
$c-telescope-bg-a: rgba(212,208,204,0.5);
$c-telescope-bg-active: #cec9c6;
$c-telescope-bg-active-a: rgba(158,149,143,0.5);
$c-debug: purple;

// Background Color
$base-background-color: white;

// Font Colors
$base-font-color: $c-dark-gray;
$base-accent-color: $c-blue;

// Link Colors
$base-link-color: $base-accent-color;
$hover-link-color: darken($base-accent-color, 15);
$base-button-color: $base-link-color;
$hover-button-color: $hover-link-color;

// Flash Colors
$alert-color: $c-light-yellow;
$error-color: $c-light-red;
$notice-color: lighten($base-accent-color, 40);
$success-color: $c-light-green;

// Border color
$base-border-color: $c-light-gray;
$base-border: 1px solid $base-border-color;