
.page-footer {
  @include font-size(0.8125);
  @include alias(normal);

  color: $c-black;

  p {
    display: inline-block;
    margin: 0;
    white-space: pre-wrap;
  }

  em {
    @include f-base-italic;
  }

  a {
    border-bottom: 1px dotted $c-telescope-bg-active;
    border-bottom-color: $c-telescope-bg-active-a;
    color: $c-black;
  }
  a:hover {
    border-bottom-color: $c-black;
    color: $c-black;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding-bottom: 1em;
  }
}
