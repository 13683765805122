// ---------------------------------------------------------------
// Mixins

// Mixin: Image replacement

%kellumreplace {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin imagereplacement(
    $image,
    $imagetype: 'png',
    $imageheight: 0,
    $imagewidth: 0,
    $imageroot: '/assets/images/' ) {
	background-color: transparent;
  background-image: url( #{ $imageroot + $image + '.' + $imagetype } );
  height: #{ $imageheight * 1px};
  width: #{ $imagewidth * 1px};
  background-repeat: no-repeat;
  @extend %kellumreplace;
  display: inline-block;
}

// Mixin: SVG Image replacement

@mixin svgreplacement(
    $image,
    $imagetype: 'svg',
    $imageheight: 0,
    $imagewidth: 0,
    $imageroot: '/assets/images/' ) {

  @include imagereplacement( $image, $imagetype, $imageheight, $imagewidth, $imageroot );

	// Fallbacks
  .no-svg & {
    background-image: none;
    text-indent: 0;
    white-space: normal;
    overflow: visible;
  }
}

// Mixin: anti-alias text

@mixin alias($style: 'antialias') {
  @if $style == 'normal'{
    -webkit-font-smoothing: subpixel-antialiased;
  } @else {
    -webkit-font-smoothing: antialiased;
  }
}

// Mixin: fonts in both rems and px

@mixin font-size($sizeValue: 1) {
  font-size: ($sizeValue * 16) + px;
  font-size: $sizeValue + rem;
}

// Mixin: transparent background color

@mixin slight-background($alpha: 0.6) {
  background: rgba(255,255,255,$alpha);
}

// ---------------------------------------------------------------